import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ZitadelUser, ZitadelUsers } from '../interfaces/zitadel-user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userEndpont = 'https://auth.praceprofirmy.cz/v2/users';

  constructor(private readonly httpClient: HttpClient) {}

  private storedUsers: { [id: string]: ZitadelUser } = {};

  private getStoredUser(id: string): ZitadelUser | null {
    return this.storedUsers[id];
  }

  async getUserById(userId: string): Promise<ZitadelUser> {
    const localUser = this.getStoredUser(userId);

    if (localUser) return localUser;
    const user = await firstValueFrom(this.fetchUser(userId));
    this.storeUser(user);

    return user;
  }

  private fetchUser(id: string): Observable<ZitadelUser> {
    return this.httpClient.get<ZitadelUser>(`${this.userEndpont}/${id}`);
  }

  fetchUsers(): Observable<ZitadelUsers> {
    return this.httpClient.post<ZitadelUsers>(`${this.userEndpont}`, {});
  }

  private storeUser(user: ZitadelUser) {
    this.storedUsers[user.user.userId] = user;
  }
}
